(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/marketplace/components/router/assets/javascripts/marketplace-onboarding.js') >= 0) return;  svs.modules.push('/modules/marketplace/components/router/assets/javascripts/marketplace-onboarding.js');

'use strict';

const {
  ReactIcon: Icon,
  ReactPaper: Paper
} = svs.ui;
const {
  Link
} = ReactRouterDOM;
const {
  WidgetArea
} = svs.components.lbUtils.widgetArea;
const {
  Hero
} = svs.components.marketplace.hero;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_MD
} = svs.components.lbUtils.useMediaQuery;
const MarketplaceOnboarding = () => {
  const size = useMediaQuery(breakpoints.down(BREAKPOINT_MD)) ? 700 : 900;
  return React.createElement(React.Fragment, null, React.createElement(WidgetArea, {
    className: "marketplace-widget",
    widgetArea: "infoWidgetAreaTop"
  }), React.createElement("div", {
    className: "marketplace-onboarding-papers"
  }, React.createElement(Paper, {
    branding: "sport",
    className: "marketplace-onboarding-paper"
  }, React.createElement(Link, {
    className: "marketplace-onboarding-link qa-onboarding-together-link",
    to: "/lag"
  }, React.createElement(Icon, {
    color: "fc-sport-75",
    icon: "players",
    size: size
  }), React.createElement("h3", null, "Spela i lag p\xE5 n\xE5gra av v\xE5ra mest popul\xE4ra spel"))), React.createElement(Paper, {
    branding: "sport",
    className: "marketplace-onboarding-paper"
  }, React.createElement(Link, {
    className: "marketplace-onboarding-link qa-onboarding-retail-link",
    to: "/ombudslag"
  }, React.createElement(Icon, {
    color: "fc-sport-75",
    icon: "andelsspel",
    size: size
  }), React.createElement("h3", null, "K\xF6p butiksandelar och ta del av experternas strategier")))), React.createElement(WidgetArea, {
    className: "marketplace-widget",
    widgetArea: "infoWidgetArea"
  }));
};
setGlobal('svs.marketplace.components.router.MarketplaceOnboarding', MarketplaceOnboarding);

 })(window);